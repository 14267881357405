<template>
  <section class="Numbers Section">
    <Pastille :pastille-set="pastilleSet">
      <div class="Numbers--Content container-xl">
        <div class="Numbers--ContentText">
          <span class="Heading Heading-TitleSmall">
            HelloAsso, c'est quoi ?
          </span>
          <h2 class="Section--Title h3">
            La plateforme des associations
          </h2>
          <p class="Numbers--Intro">
            Créée en 2009, HelloAsso est une entreprise solidaire qui
            accompagne le développement des activités et projets
            associatifs en France. Aujourd’hui, vous trouverez sur
            HelloAsso :
          </p>
        </div>
        <figure>
          <img
            src="~/assets/homepage/numbers-sport.2182788.png"
            alt=""
            loading="lazy"
          />
        </figure>
        <div class="Numbers--List row">
          <div class="Numbers--ListItem">
            <p class="Numbers--ListItemNumber bold">
              {{ showcaseOrganizationCounter }}
            </p>
            <p class="Numbers--ListItemName">
              Associations et clubs à découvrir
            </p>
          </div>
          <div class="Numbers--ListItem">
            <p class="Numbers--ListItemNumber bold">106&nbsp;792</p>
            <p class="Numbers--ListItemName">
              Activités à faire ensemble
            </p>
          </div>
          <div class="Numbers--ListItem">
            <p class="Numbers--ListItemNumber bold">93&nbsp;769</p>
            <p class="Numbers--ListItemName">Projets à soutenir</p>
          </div>
        </div>
      </div>
    </Pastille>
  </section>
</template>

<script setup lang="ts">
import { ref } from '#imports'
import Pastille from './Pastille.vue'

// TODO use asyncData -> see HomeHero (in todo as well)
// const showcaseOrganizationCounter = await getOrganizationCount()
const showcaseOrganizationCounter = '322 350'
const pastilleSet = ref({
  Air: {
    x: 46,
    y: 7,
    width: 8,
    height: 3
  },
  Mint: {
    x: 4,
    y: 4,
    width: 8,
    height: 4
  },
  Desert: {
    x: 70,
    y: 70,
    width: 16,
    height: 8
  },
  Iris: {
    x: 35,
    y: 60,
    width: 4,
    height: 6
  },
  Retrowave: {
    x: 46,
    y: 40,
    width: 6,
    height: 6
  }
})
</script>

<style scoped lang="scss">
.Numbers {
  &--Content {
    display: grid;
    grid-template: repeat(2, auto) / repeat(2, 1fr);
    row-gap: 4em;

    &Text {
      align-self: center;
      display: flex;
      flex-direction: column;
      gap: var(--hads-spacing-400);
    }

    @media (width < 700px) {
      grid-template-columns: 1fr;

      figure {
        display: none;
      }
    }

    @media (700px <= width < 1000px) {
      grid-template-columns: 0.7fr 0.3fr;
      column-gap: 1em;
    }

    .Heading {
      text-transform: uppercase;
      color: var(--hads-color-iris);
      font-size: 0.9em;
    }
  }

  &--List {
    gap: 2em;
    grid-column-end: span 2;

    @media (width < 650px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    @media (width >= 650px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-items: center;
    }

    &Item {
      &Number {
        @media (width < 850px) {
          font-size: 3em;
        }

        font-size: 4em;
        color: var(--hads-color-iris);
        font-weight: bold;
        margin-bottom: 0;
        text-align: center;
      }

      &Name {
        text-transform: uppercase;
        color: var(--ha-color-text);
        font-weight: bold;
        text-align: center;
      }
    }
  }
}
</style>
