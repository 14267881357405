<template>
  <div class="city-complete-wrapper">
    <HaIcon :icon="faLocationDot" />
    <LegacyHADSDropdown
      hide-title-icon
      :is-open="isDropdownOpen"
      @mousedown="temporaryDisableDropdownClosing"
      @click-outside="closeDropdown"
    >
      <template #title>
        <div class="input-wrapper">
          <span
            ref="cityInputRef"
            :contenteditable="contentEditableAttrValue"
            :class="{ IsInputNotInUse }"
            @input="inputEventHandling"
            @focus="focusBehavior"
            @touchend="focusBehavior"
            @focus.once="componentHasBeenUsed"
            @focusout="safeSearchWholeFrance"
            @keydown.right.exact.prevent="userAcceptingCitySuggestion"
            @keydown.enter.exact.prevent="userAcceptingCitySuggestion"
            @keydown.tab.exact="temporaryDisableDropdownClosing"
          />
          <span
            class="city-suggest"
            @click="userAcceptingCitySuggestion"
            >{{ citySuggestion }}</span
          >
        </div>
        <button
          v-if="cityInputTextContent.length"
          class="xmark-wrapper"
          @click="abortCitySearch"
        >
          <HaIcon :icon="faXmark" />
        </button>
      </template>
      <template
        v-if="links.length || recentSearchedCities.length"
        #content
      >
        <div v-if="links.length">
          <ListTitle>Suggestions</ListTitle>
          <HADSListItems>
            <CityItem
              v-for="(link, index) in links"
              :key="index"
              :city="link.text"
              :geo-context="link.properties.geoContext"
              @click="changeCityFromDropdown(link)"
            />
          </HADSListItems>
          <ListTitle v-if="!areSomeCitiesSuggered">
            Pas de résultat 🌵
          </ListTitle>
        </div>
        <div v-if="recentSearchedCities.length">
          <ListTitle>Recherches récentes</ListTitle>
          <HADSListItems>
            <CityItem
              v-for="(link, index) in recentSearchedCities"
              :key="index"
              :city="link.text"
              :geo-context="link.properties.geoContext"
              :icon-start="faClockRotateLeft"
              @click="changeCityFromDropdown(link)"
            />
          </HADSListItems>
        </div>
      </template>
    </LegacyHADSDropdown>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from '#imports'
import {
  faLocationDot,
  faXmark,
  faClockRotateLeft
} from '@fortawesome/pro-solid-svg-icons'
import { functions } from '@ha/helpers'
import { getProbableCities } from '@/helpers/address'
import {
  clearSelection,
  selectAllText,
  plainTextOnlyPolyfill,
  type HTMLATTRContentEditable
} from '@/helpers/contentEditable'
import LegacyHADSDropdown from '@/domains/home/components/HomeActivities/LegacyHADSDropdown.vue'
import HADSListItems from '@/domains/design_system/atoms/HADSListItems.vue'
import {
  CustomLinkTypes,
  type HADSButtonType,
  type NormalButton
} from '@/domains/design_system/atoms/HADSButton/HADSButton.interface'
import CityItem from '@/domains/design_system/atoms/ListItems/CityItem.vue'
import ListTitle from '@/domains/design_system/atoms/ListItems/ListTitle.vue'
import { type Ref } from '#imports'
import { HaIcon } from '@ha/components-v3'

const PLACEHOLDER_STRING = computed(() => 'partout en France')

const hasNeverBeenUsed = ref(true)

const contentEditableAttrValue: Ref<HTMLATTRContentEditable> =
  ref(true)

const recentSearchedCities: Ref<HADSButtonType[]> = ref([])
const cityInputTextContent = ref('')
const cityInputRef = ref()
const citySuggestion = ref('')
const isDropdownOpen = ref(false)
const tempDisableDropdownClosing = ref(false)

const IsInputNotInUse = computed(
  () =>
    hasNeverBeenUsed.value &&
    (!cityInputTextContent.value.length ||
      cityInputTextContent.value === PLACEHOLDER_STRING.value)
)

const temporaryDisableDropdownClosing = () => {
  tempDisableDropdownClosing.value = true
}

const searchWholeFrance = () => {
  updateCityInput({ cityObject: null })

  emit('city-updated', {
    text: '',
    icon: 'location-dot',
    properties: {
      place_department: '',
      geoContext: ''
    },
    customLinkType: CustomLinkTypes.cityObject
  })

  unfocusBehavior()
}

const safeSearchWholeFrance = () => {
  if (!cityInputTextContent.value.length) {
    hasNeverBeenUsed.value = true
    searchWholeFrance()
  }
}

const permanentLinks: Ref<HADSButtonType[]> = ref([
  // {
  //   text: 'Autour de moi',
  //   icon: 'crosshairs',
  //   handler: searchAroundMe
  // } as NormalButton,
  // {
  //   text: PLACEHOLDER_STRING.value,
  //   icon: 'map',
  //   handler: searchWholeFrance
  // } as NormalButton
])

const dynamicLinks: Ref<HADSButtonType[]> = ref([])

const links = computed((): HADSButtonType[] => {
  if (dynamicLinks.value.length) {
    if (cityInputTextContent.value.toLowerCase().includes('france')) {
      return [...permanentLinks.value, ...dynamicLinks.value]
    }

    return dynamicLinks.value
  }

  return permanentLinks.value
})

const openDropdown = () => {
  isDropdownOpen.value = true
}

const closeDropdown = (force = false) => {
  if (force || document.activeElement !== cityInputRef.value) {
    isDropdownOpen.value = false
  }
}

const areSomeCitiesSuggered = computed((): boolean => {
  if (
    cityInputTextContent.value === PLACEHOLDER_STRING.value ||
    cityInputTextContent.value.length < 3
  ) {
    return true
  }

  return Boolean(
    dynamicLinks.value.length && cityInputTextContent.value.length
  )
})

const componentHasBeenUsed = () => {
  hasNeverBeenUsed.value = false
}

const focusBehavior = () => {
  if (cityInputTextContent.value) {
    if (cityInputTextContent.value === PLACEHOLDER_STRING.value) {
      abortCitySearch()
    } else {
      selectAllText(cityInputRef)
    }
  }

  openDropdown()
}

const unfocusBehavior = () => {
  safeSearchWholeFrance()

  clearSelection()

  if (!tempDisableDropdownClosing.value) {
    closeDropdown()
  }

  tempDisableDropdownClosing.value = false
  ;(document.activeElement as HTMLElement).blur()
}

const changeCityFromDropdown = (link) => {
  tempDisableDropdownClosing.value = false
  unfocusBehavior()
  updateCityInput({ cityObject: link })
  emit('city-updated', link)
}

const createCityButton = (
  cityObject,
  isObjectReady = false
): NormalButton => {
  if (!isObjectReady) {
    cityObject = {
      text: cityObject.city,
      icon: cityObject.icon || faLocationDot,
      properties: {
        place_department: cityObject.county,
        geoContext: `${cityObject.postcode}, ${cityObject.county}`
      }
    }
  }

  return {
    ...cityObject,
    customLinkType: CustomLinkTypes.cityObject
  }
}

const suggestCities = functions.debounce(async () => {
  const probableCities = await getProbableCities(
    cityInputTextContent.value,
    5
  )
  dynamicLinks.value = probableCities.map((feature) => {
    const city = feature.properties.city.toLowerCase()
    const postcode = feature.properties.postcode
    const county = feature.properties.context.split(', ')[1]

    return createCityButton({ city, postcode, county })
  })

  const mostLikelyCityName = dynamicLinks.value[0].text
  // Strict handle of string start to suggest (avoiding to purpose "Paris" if user writed "Prari").
  if (
    mostLikelyCityName.startsWith(
      cityInputTextContent.value.toLowerCase()
    )
  ) {
    citySuggestion.value = mostLikelyCityName
  }
})

const inputEventHandling = () => {
  cityInputTextContent.value = cityInputRef.value.textContent

  // Clean suggestion to avoid old result display for .5s if query is still throttled.
  citySuggestion.value = ''
  suggestCities()
}

const emit = defineEmits(['city-updated'])

const updateCityInput = ({ cityObject }) => {
  // cityObject.text can be null to reset to All France.
  // it also can be empty string to display empty content to user
  // and finally can be a normal string.
  if (cityObject === null) {
    cityObject = {
      text: PLACEHOLDER_STRING.value
    }
  }

  citySuggestion.value = ''
  cityInputRef.value.textContent = cityObject.text || ''
  cityInputTextContent.value = cityObject.text || ''

  if (
    typeof cityObject !== 'string' &&
    cityObject.text !== PLACEHOLDER_STRING.value
  ) {
    emit('city-updated', cityObject)

    const citiesNames = recentSearchedCities.value.map(
      (el) => el.text
    )

    if (
      cityObject.text.length &&
      !citiesNames.includes(cityObject.text)
    ) {
      cityObject.icon = faClockRotateLeft
      recentSearchedCities.value.push(
        createCityButton(cityObject, true)
      )
    }
  }

  if (cityInputTextContent.value === '') {
    dynamicLinks.value = []
  }
}

onMounted(() => {
  updateCityInput({ cityObject: null })

  contentEditableAttrValue.value = plainTextOnlyPolyfill()
})

const userAcceptingCitySuggestion = () => {
  if (
    citySuggestion.value.length >= cityInputTextContent.value.length
  ) {
    updateCityInput({ cityObject: dynamicLinks.value[0] })
    emit('city-updated', dynamicLinks.value[0])
    closeDropdown(true)
    unfocusBehavior()
  }
}

const abortCitySearch = () => {
  updateCityInput({ cityObject: '' })
  cityInputRef.value.focus()
}
</script>

<style lang="scss" scoped>
.city-complete-wrapper {
  display: flex;
  align-items: center;
  gap: var(--hads-spacing-200);
  color: var(--hads-color-iris);
  line-height: 1;

  .xmark-wrapper {
    display: none;
  }

  &:focus-within {
    .fa-location-dot {
      animation: 0.5s spin-up-location-dot ease-in-out;

      @keyframes spin-up-location-dot {
        50% {
          transform: translateY(-0.25em) rotateY(180deg);
        }
      }
    }

    .xmark-wrapper {
      display: inline-block;
    }
  }
}

.fa-xmark,
.fa-location-dot {
  cursor: pointer;
}

.fa-location-dot {
  transition: var(--hads-transition-simple);
}

button {
  display: none;
  color: inherit;
  padding: 0;
  pointer-events: none;

  .fa-xmark {
    font-size: 0.5em;
    vertical-align: middle;
    pointer-events: all;

    &:hover {
      transform: rotate(90deg);
    }
  }
}

[contenteditable]:not(.IsInputNotInUse),
:not(.IsInputNotInUse) + .city-suggest,
:deep(.content-wrapper-inner .hads-button) {
  text-transform: capitalize;
}

.input-wrapper {
  position: relative;
  display: grid;

  [contenteditable],
  .city-suggest {
    white-space: nowrap;
    grid-area: 1 / 1 / 2 / 2;
  }

  .city-suggest {
    cursor: pointer;
    color: var(--hads-color-storm);
  }

  [contenteditable] {
    --component-text-color: var(--hads-color-iris);

    border: none;
    display: inline-block;
    width: fit-content;
    min-width: 1em;
    transition: border-width var(--hads-transition-simple);
    position: relative;
    z-index: 2;
    color: var(--component-text-color);
    outline: none;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: var(--hads-color-storm);
      left: 0;
      bottom: 0;
    }

    &:empty {
      // Used to make underline all the way.
      width: 100%;
    }

    &:empty,
    &.IsInputNotInUse {
      --component-text-color: var(--hads-color-storm);
    }
  }
}
</style>
