<template>
  <div class="Pastille-InnerWrapper">
    <ClientOnly>
      <div class="Oblong-Container">
        <Oblong
          v-for="color of colors"
          :key="color"
          :color="color"
          :style="byColorCssDataStack[color]"
        />
      </div>
    </ClientOnly>
    <div class="Pastille-SlotContainer">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from '#imports'
import Oblong from '@/domains/design_system/atoms/HADSOblong.vue'
import { AvailableOblongColors } from '@/domains/design_system/models/colors'

const props = defineProps({
  pastilleSet: {
    type: Object,
    default: () => ({})
  }
})

const colors = ref([
  AvailableOblongColors.Mint,
  AvailableOblongColors.Desert,
  AvailableOblongColors.Retrowave,
  AvailableOblongColors.Rose,
  AvailableOblongColors.Green
])

const byColorCssDataStack = ref({})

onBeforeMount(() => {
  // Check all manually setted pastilles then assign them to data.
  for (const key in props.pastilleSet) {
    byColorCssDataStack.value[key] = props.pastilleSet[key]
  }

  for (const color of colors.value) {
    const [width, height] = setSize()
    const [x, y] = setCoordinates()

    const settings = {
      x,
      y,
      width,
      height
    }

    // Generating default overrideable values.
    Object.assign(settings, byColorCssDataStack.value[color])

    // Assign values to still untreated pastilles.
    byColorCssDataStack.value[color] = generateCssStack(settings)
  }
})

const getRandomInRange = (min: number, max: number): number => {
  return Math.random() * (max - min) + min
}

const setSize = () => {
  // Em units as end.
  const limits: [number, number] = [2, 8]

  return [getRandomInRange(...limits), getRandomInRange(...limits)]
}

const setCoordinates = () => {
  // Percents units at end.
  const limits: [number, number] = [0, 100]

  return [getRandomInRange(...limits), getRandomInRange(...limits)]
}

const generateCssStack = ({ x, y, width, height }) => {
  return `
    --width: ${width}em;
    --height: ${height}em;
    width: var(--width);
    height: var(--height);
    border-radius: max(var(--width), var(--height));
    top: ${y}%;
    left: ${x}%;
  `
  // Secure version of Top & Left (avoid overlap) :
  // top: clamp(0em, ${y}%, calc(100% - var(--height)));
  // left: clamp(0em, ${x}%, calc(100% - var(--width)));
}
</script>

<style lang="scss" scoped>
.Pastille {
  &-InnerWrapper,
  &-SlotContainer {
    position: relative;
  }
}

.Oblong {
  &-Container {
    position: absolute;
    z-index: -1;
    top: 0;
    height: 100%;
    width: 100%;
    overflow-x: clip;
  }

  // in mobile no position is good
  @include mediaQuery(900, 'max') {
    &-Item {
      display: none;
    }
  }
}
</style>
