<template>
  <section class="width-spacer height-spacer">
    <SectionTitle>
      <template #title>
        <div class="title-wrapper h3">
          <span>Les activités du moment</span>
          <LegacyCityCompleteInput
            @city-updated="updateRedirectionLinkCity"
          />
        </div>
      </template>
      <template #content>
        <SeoLink
          v-if="hits?.length"
          :to="finalRedirectionLink"
          data-ux="home_activities_link"
          text="Découvrir plus d'activités"
          class="home-activities-title-link"
        />
      </template>
    </SectionTitle>

    <ChipsFilters v-model="filterState" />

    <Skeleton
      v-if="isLoading"
      :index-name="query.indexName"
      :skeleton-number="3"
    />
    <div
      v-else-if="!hits?.length"
      class="no-result"
    >
      <p class="h3">Aucun événement disponible</p>
      <img
        src="@/assets/images/activity_empty.svg"
        alt="No activity found"
      />
      <p>
        Nous n'avons pas trouvé de résultat correspondant à vos
        critères.<br />
        Modifiez vos filtres ou allez plus loin en découvrant
        <SeoLink
          :to="baseUrlRedirect"
          data-ux="home_no_activities_link"
          text="plus d'activités"
        />
      </p>
    </div>
    <template v-else>
      <Grid
        tag="ul"
        layout="4"
        spacing="medium"
      >
        <ThumbnailActivity
          v-for="(hit, index) in hits"
          :key="hit.objectID"
          :hit="hit"
          :index="index"
          :context-from="HitModes.HIT_LIST"
        />
      </Grid>
      <HADSButton
        v-if="hits.length"
        :to="finalRedirectionLink"
        class="more-activites-btn"
      >
        Voir plus d'activités
      </HADSButton>
    </template>
  </section>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import SectionTitle from '@/domains/design_system/molecules/SectionTitle.vue'
import SeoLink from '@/domains/design_system/molecules/SeoLink/SeoLink.vue'
import { HitModes } from '@/helpers/enums'
import useSearch from '~/domains/search/composables/search.composable'
import Grid from '@/domains/design_system/organisms/Grid.vue'
import ThumbnailActivity from '@/components/Thumbnail/ThumbnailActivity.vue'
import LegacyCityCompleteInput from '@/domains/home/components/HomeActivities/LegacyCityCompleteInput.vue'
import HADSButton from '@/domains/design_system/atoms/HADSButton/HADSButton.vue'
import ChipsFilters from '@/domains/home/components/HomeActivities/ChipsFilters.vue'
import Skeleton from '@/components/Skeleton/Skeleton.vue'
import type { HaMultipleQueriesQueryUI } from '~/domains/search/interfaces/query.interface'
import { SEARCH_ALL_INDEX } from '~/domains/search/interfaces/search.interface'

import {
  ACTIVITY_FORM_TYPE,
  type Filters
} from '~/domains/search/interfaces/filters.interface'

const storeQueryKey = 'HOME_SEARCH'

const { isLoading, store, fetchByKey, fetchAllSSR } = useSearch()

const query = computed(() => store.getQuery(storeQueryKey))

const homeActivitiesAlgoliaQuery: HaMultipleQueriesQueryUI = {
  indexName: SEARCH_ALL_INDEX.ACTIVITIES,
  params: {
    hitsPerPage: 8,
    filters: {
      form_type: ACTIVITY_FORM_TYPE.EVENT
    }
  }
}

store.setQuery(storeQueryKey, homeActivitiesAlgoliaQuery)

const filterState = ref({})

watch(filterState, () => {
  updateQueryFilters(filterState.value)
})

const results = computed(() => store.getResult(storeQueryKey))
const hits = computed(() => results.value?.hits ?? [])

const baseUrlRedirect = '/e/recherche/activites'

const finalRedirectionLink = ref(baseUrlRedirect)

const getRedirectionLink = () => {
  if (hits.value?.length) {
    const queryUrl = new URLSearchParams(
      query.value.params.filters as any
    )
    return `${baseUrlRedirect}?${queryUrl.toString()}`
  } else {
    return baseUrlRedirect
  }
}

const updateRedirectionLinkCity = async (cityObject?: {
  text: string
  properties: {
    place_department: string
  }
}) => {
  let placeCity = cityObject?.text
  let placeDepartment = cityObject?.properties.place_department

  placeCity = placeCity.length ? placeCity : undefined
  placeDepartment = placeDepartment.length
    ? placeDepartment
    : undefined

  homeActivitiesAlgoliaQuery.params.filters.place_city = placeCity
  homeActivitiesAlgoliaQuery.params.filters.place_department =
    placeDepartment

  store.setQuery(storeQueryKey, homeActivitiesAlgoliaQuery)
  const results = await fetchByKey(storeQueryKey)
  if (results) {
    store.setResult(storeQueryKey, results[storeQueryKey])
  } else {
    console.warn('results was undefined', results)
  }

  finalRedirectionLink.value = getRedirectionLink()
}

const updateQueryFilters = async (filters: Filters) => {
  const newFilter = {
    place_city: query.value.params.filters.place_city,
    place_department: query.value.params.filters.place_department,
    ...filters
  }

  for (const key in newFilter) {
    if (
      Object.prototype.hasOwnProperty.call(newFilter, key) &&
      newFilter[key] === undefined
    ) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete newFilter[key]
    }
  }

  homeActivitiesAlgoliaQuery.params.filters = newFilter

  store.setQuery(storeQueryKey, homeActivitiesAlgoliaQuery)

  const results = await fetchByKey(storeQueryKey)

  if (results) {
    store.setResult(storeQueryKey, results[storeQueryKey])
  } else {
    console.warn('results was undefined', results)
  }

  finalRedirectionLink.value = getRedirectionLink()
}

await fetchAllSSR('home-activity')
</script>

<style scoped lang="scss">
section {
  display: flex;
  flex-direction: column;
  gap: var(--hads-spacing-500);

  .no-result,
  .more-activites-btn {
    align-self: center;
  }
}

.no-result {
  text-align: center;

  img {
    max-height: 25vh;
    margin-bottom: 1em;
  }
}

.title-wrapper {
  display: flex;
  gap: var(--hads-spacing-200);
  flex-wrap: wrap;
  margin-bottom: 0;
}

.home-activities-title-link {
  @include mediaQuery(800, 'max') {
    display: none;
  }
}
</style>
