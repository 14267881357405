<template>
  <section class="Cities Section section-crossed">
    <div class="container-xl">
      <h2 class="Section--Title h3">Dans toute la France</h2>
      <h3 class="Section--Subtitle">
        Découvrez toutes les activités près de chez vous !
      </h3>
      <ul class="Cities--List">
        <li class="Cities--ListItem">
          <nuxt-link
            href="/e/reg/ile-de-france/dep/paris/ville/paris"
            data-ux="Showcase_HomeGP_Cities_Paris"
          >
            <figure>
              <img
                src="@/assets/homepage/paris.13b50d0.webp"
                alt=""
                loading="lazy"
              />
              <figcaption>
                <span class="Highlighted Highlighted-Iris"
                  >Paris</span
                >
              </figcaption>
            </figure>
            <h4 class="h5 Cities--ListItemName">Paris</h4>
            <p class="Cities--ListItemResults">
              8&nbsp;743 activités
            </p>
          </nuxt-link>
        </li>
        <li class="Cities--ListItem">
          <nuxt-link
            href="/e/reg/bretagne/dep/ille-et-vilaine/ville/rennes"
            data-ux="Showcase_HomeGP_Cities_Rennes"
          >
            <figure>
              <img
                src="~/assets/homepage/rennes.7cef954.webp"
                alt=""
                loading="lazy"
              />
              <figcaption>
                <span class="Highlighted">Rennes</span>
              </figcaption>
            </figure>
            <h4 class="h5 Cities--ListItemName">Rennes</h4>
            <p class="Cities--ListItemResults">906 activités</p>
          </nuxt-link>
        </li>
        <li class="Cities--ListItem">
          <nuxt-link
            href="/e/reg/hauts-de-france/dep/nord/ville/lille"
            data-ux="Showcase_HomeGP_Cities_Lille"
          >
            <figure>
              <img
                src="@/assets/homepage/lille.3e18ca0.webp"
                alt=""
                loading="lazy"
              />
              <figcaption>
                <span class="Highlighted Highlighted-Desert"
                  >Lille</span
                >
              </figcaption>
            </figure>
            <h4 class="h5 Cities--ListItemName">Lille</h4>
            <p class="Cities--ListItemResults">947 activités</p>
          </nuxt-link>
        </li>
        <li class="Cities--ListItem">
          <nuxt-link
            href="/e/reg/provence-alpes-cote--d---azur/dep/bouches-du-rhone/ville/marseille"
            data-ux="Showcase_HomeGP_Cities_Marseille"
          >
            <figure>
              <img
                src="@/assets/homepage/marseille.30148d1.webp"
                alt=""
                loading="lazy"
              />
              <figcaption>
                <span class="Highlighted Highlighted-Retrowave">
                  Marseille
                </span>
              </figcaption>
            </figure>
            <h4 class="h5 Cities--ListItemName">Marseille</h4>
            <p class="Cities--ListItemResults">
              1&nbsp;995 activités
            </p>
          </nuxt-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<style scoped lang="scss">
.Cities {
  width: 100%;

  h2 {
    margin-bottom: 0;
    font-size: 2em;
  }

  h3 {
    color: var(--ha-color-text-light);
    font-weight: 400;
    font-size: 1.5em;
    margin-bottom: 1em;
  }

  &--List {
    gap: 2em;

    @media (width < 1000px) {
      display: grid;
      justify-items: center;
    }

    @media (500px <= width < 900px) {
      grid-template-columns: repeat(2, 1fr);
      width: fit-content;
    }

    @media (width >= 900px) {
      display: flex;
      justify-content: center;
    }

    &Item {
      display: flex;
      flex-direction: column;

      &Name,
      &Results {
        margin-bottom: 0;
      }

      &Results {
        font-size: 0.9em;
      }

      figure {
        position: relative;
        display: grid;
        place-items: center;
        margin-bottom: 1em;
        width: 100%;

        figcaption {
          position: absolute;
          color: white;
          font-size: 1.75em;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
