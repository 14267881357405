export enum AvailableOblongColors {
  Mint = 'Mint',
  MintLight = 'MintLight',
  Iris = 'Iris',
  IrisLight = 'IrisLight',
  Desert = 'Desert',
  DesertLight = 'DesertLight',
  Retrowave = 'Retrowave',
  RetrowaveLight = 'RetrowaveLight',
  Air = 'Air',
  Green = 'Green',
  Rose = 'Rose'
}
