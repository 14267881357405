<template>
  <div
    ref="dropdownRef"
    class="hads-dropdown"
  >
    <div class="title-wrapper">
      <slot name="title" />
      <HaIcon
        v-if="!hideTitleIcon"
        :icon="faChevronDown"
      />
    </div>
    <div
      v-if="$slots.content"
      class="content-wrapper-outer"
      :open="isOpen"
    >
      <div class="content-wrapper-inner">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { HaIcon } from '@ha/components-v3'
import { onBeforeMount, onBeforeUnmount, ref } from 'vue'

const props = defineProps({
  hideTitleIcon: {
    type: Boolean,
    default: false
  },
  isOpen: {
    type: Boolean,
    default: false
  }
})

const dropdownRef = ref()

const emit = defineEmits(['click-outside'])

const clickOutsideDropdownEvent = (e) => {
  if (!props.isOpen) {
    return
  }

  if (!dropdownRef.value.contains(e.target)) {
    emit('click-outside')
  }
}

onBeforeMount(() => {
  window.addEventListener('click', clickOutsideDropdownEvent)
})

onBeforeUnmount(() => {
  window.removeEventListener('click', clickOutsideDropdownEvent)
})
</script>

<style lang="scss" scoped>
.hads-dropdown {
  position: relative;

  .title-wrapper {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-wrap: nowrap;
    gap: var(--hads-spacing-250);
    cursor: pointer;
    color: currentcolor;

    svg {
      font-size: 0.6em;
    }
  }

  .content-wrapper-outer {
    position: absolute;
    z-index: 2;
    top: 100%;
    padding-top: var(--hads-spacing-100);
    width: fit-content;

    &:not([open]),
    &[open='false'] {
      display: none;
    }

    &[open] {
      .content-wrapper-inner {
        animation: pop var(--hads-transition-simple-duration) ease-out;

        @keyframes pop {
          from {
            scale: 0;
            opacity: 0;
          }
        }
      }
    }
  }

  .content-wrapper-inner {
    width: fit-content;
    padding: var(--hads-spacing-200);
    border-radius: var(--hads-radius-400);
    background: var(--hads-theme-background);
    box-shadow: var(--hads-box-shadow);
    transform-origin: top;
  }
}
</style>
